body {
  margin: 0;
  font-family: 'Roboto', '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'Helvetica Neue', 'Ubuntu', 'Oxygen',
    'Droid Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    white-space: pre-line;
  }
}
